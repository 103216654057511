import type { EventDescription } from './types/event'
import type { AlgoliaEventItem } from './types/algolia'
import { parseEventDescriptionToAlgoliaEventItem } from '~/utils/helpers'

const LAST_VIEWED_EVENTS_LOCAL_KEY = 'last-viewed-event-ids'
const LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY = 'last-viewed-events-time'

const getLastViewedStorageKey = () => `${LAST_VIEWED_EVENTS_LOCAL_KEY}`

const safeJsonParse = (str: string, defaultReturn = []) => {
  let arr = defaultReturn || []
  try {
    arr = JSON.parse(str)
  } catch (_err) {
    console.warn('JSON.parse error', _err)
  }
  return arr
}

export const useLastViewedEvents = (loadLastSeen: boolean): any => {
  const lastViewedEvents = ref<EventDescription[]>([])
  const parsedLastViewedEvents = ref([])
  const now = Date.now()

  const getLastViewedEventsFromLocalStorage = async () => {
    if (!window?.localStorage) {
      return
    }

    const storageLastViewedEventsTime = window.localStorage.getItem(
      LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY
    )
    const storageLastViewedEventsIds = safeJsonParse(
      window.localStorage.getItem(getLastViewedStorageKey())
    )

    const thirtyMin = 1000 * 60 * 30
    const isPastThirtyMin =
      thirtyMin < now - Number(storageLastViewedEventsTime)

    if (!storageLastViewedEventsIds) {
      return
    }
    parsedLastViewedEvents.value = []

    const events = await loadEventsByIds(storageLastViewedEventsIds)
    ;(events?.data || [])
      .map((ev) => {
        ev.__order = storageLastViewedEventsIds.indexOf(ev.id) + 1
        return ev
      })
      .filter((ev) => ev.__order) // setting up for ordering
      .sort((ae, be) => ae.__order - be.__order) // actual ordering
      .map((ev) =>
        parsedLastViewedEvents.value.push(
          parseEventDescriptionToAlgoliaEventItem(ev)
        )
      )

    // Checks if the last viewed events are past 30 minutes
    // Only diplay last viewed if the first stored event id is older than 30 minutes.
    if (isPastThirtyMin) {
      lastViewedEvents.value = parsedLastViewedEvents.value
    }
  }

  const setLastViewedEvent = (eventId: string) => {
    if (!process.client || !window?.localStorage || !eventId) {
      return
    }

    const lastViewedIds = [eventId]

    const storedEventsId = window.localStorage.getItem(
      getLastViewedStorageKey()
    )

    if (storedEventsId) {
      let newStoredEventsId = safeJsonParse(storedEventsId)

      if (newStoredEventsId.includes(eventId)) {
        newStoredEventsId.splice(
          newStoredEventsId.findIndex((v: string) => v === eventId),
          1
        )
      }
      newStoredEventsId.unshift(eventId)
      newStoredEventsId = newStoredEventsId.slice(0, 10)

      window.localStorage.setItem(
        getLastViewedStorageKey(),
        JSON.stringify(newStoredEventsId)
      )

      return newStoredEventsId
    } else {
      window.localStorage.setItem(
        getLastViewedStorageKey(),
        JSON.stringify(lastViewedIds)
      )
      window.localStorage.setItem(
        LAST_VIEWED_EVENS_TIME_LOCAL_STORAGE_KEY,
        now.toString()
      )

      return lastViewedIds
    }
  }

  const loadEventsByIds = async (eventIDs: string[]) => {
    if (eventIDs?.length < 1) return []
    const params = `?include=supplier&search=hasEvent:false;id:${eventIDs.join(
      ','
    )}`
    try {
      const eventsData = await kftApiRequest(`v1/store/events/${params}`, {
        method: 'GET',
      })
      return eventsData
    } catch (e) {
      console.error('loading last seen events failed', e)
    }
  }

  onMounted(() => {
    if (loadLastSeen) {
      getLastViewedEventsFromLocalStorage()
    }
  })

  return {
    lastViewedEvents: computed(() => lastViewedEvents.value),
    setLastViewedEvent,
  }
}
